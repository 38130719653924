import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faWifi,
  faSmokingBan,
  faWheelchair,
  faSnowflake,
  faGlobeAmericas,
  faBus,
  faUtensils,
  faCoffee,
  faLaptop,
  faCouch,
  faFileAlt,
  faSwimmingPool,
  faTv,
  faShower,
  faDumbbell,
  faParking,
  faLock,
  faLevelUpAlt,
  faWalking,
  faSubway,
  faInfoCircle,
  faGraduationCap,
  faSortDown,
  faCaretDown,
  faCaretUp,
  faTrashAlt,
  faBed,
  faPlane,
  faExchangeAlt,
  faArrowLeft,
  faMapMarkerAlt,
  faFilter,
  faSearch,
  faPhone,
  faTimes,
  faCheck,
  faMinus,
  faPlus,
  faDesktop,
  faArrowsAlt,
  faCog,
  faCamera,
  faShareSquare,
  faPrint,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faArrowRight,
  faSortUp,
  faLongArrowAltRight,
  faExpandArrowsAlt,
  faSort,
  faTrain,
  faComment,
  faSearchPlus,
  faExpand,
  faExclamationCircle,
  faTag,
  faCircle,
} from '@fortawesome/free-solid-svg-icons'

import {
  faTrashAlt as faTrash,
  faCalendarAlt,
  faPaperPlane,
} from '@fortawesome/free-regular-svg-icons'

import {
  faFacebookSquare,
  faTwitter,
  faLinkedin,
  faYoutube,
  faInstagram,
  faSnapchatGhost,
  faBloggerB,
  faYoutubeSquare,
  faInstagramSquare,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons'

import {
  faFlowerDaffodil,
  faKnifeKitchen,
  faWasher,
  faMicrowave,
  faCoffeePot,
  faBooks,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faCloudUpload,
  faCloud,
  faFile,
  faFileCheck,
  faPlay,
} from '@fortawesome/pro-regular-svg-icons'

import {
  faShoppingCart,
  faTimes as faTimesLight,
} from '@fortawesome/pro-light-svg-icons'

import { faTiktokSquare } from '@awesome.me/kit-b20690c856/icons/kit/custom'


library.add(
  faWifi,
  faSmokingBan,
  faWheelchair,
  faSnowflake,
  faGlobeAmericas,
  faBus,
  faUtensils,
  faCoffee,
  faLaptop,
  faCouch,
  faFileAlt,
  faSwimmingPool,
  faTv,
  faShower,
  faDumbbell,
  faParking,
  faLock,
  faLevelUpAlt,
  faWalking,
  faWalking,
  faSubway,
  faInfoCircle,
  faCaretDown,
  faCaretUp,
  faTrashAlt,
  faTrash,
  faGraduationCap,
  faCalendarAlt,
  faSortDown,
  faBed,
  faPlane,
  faExchangeAlt,
  faArrowLeft,
  faMapMarkerAlt,
  faFilter,
  faFacebookSquare,
  faTwitter,
  faTiktok,
  faTiktokSquare,
  faLinkedin,
  faYoutube,
  faInstagram,
  faSnapchatGhost,
  faBloggerB,
  faPaperPlane,
  faSearch,
  faPhone,
  faTimes,
  faTimesLight,
  faCheck,
  faMinus,
  faPlus,
  faCircle,
  faPlay,
  faDesktop,
  faArrowsAlt,
  faCog,
  faCamera,
  faShareSquare,
  faPrint,
  faChevronLeft,
  faChevronRight,
  faArrowRight,
  faSortUp,
  faLongArrowAltRight,
  faExpandArrowsAlt,
  faSort,
  faTrain,
  faFlowerDaffodil,
  faKnifeKitchen,
  faWasher,
  faMicrowave,
  faCoffeePot,
  faBooks,
  faYoutubeSquare,
  faInstagramSquare,
  faComment,
  faChevronUp,
  faSearchPlus,
  faExpand,
  faExclamationCircle,
  faShoppingCart,
  faCloudUpload,
  faCloud,
  faFile,
  faFileCheck,
  faTag,
)
